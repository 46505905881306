function Education() {
    return (
        <div class="col-md-8">
            <section id="project" class="py-1">
                To be developed... checkout professional experiences
            </section>
        </div>
    )
}

export default Education;