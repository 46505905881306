function Header() {
    return (
        <div>
            <section id="header">
                <h2>Nathan Phan's Page</h2>
            </section>

            <hr></hr>
        </div>
    )
}

export default Header;